import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Drawer,
  makeStyles,
  IconButton,
  Card,
  CardHeader,
  CardContent,
  Grid,
  CircularProgress,
} from '@material-ui/core';
import singleSpa from 'single-spa';
import {
  useQuery,
  gql,
  useMutation,
  useSubscription,
  useLazyQuery,
} from '@apollo/client';
import { ToastContainer, toast } from 'react-toastify';
import * as Yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { Storage } from 'aws-amplify';
import shortid from 'shortid';
import {
  BasePage,
  FdTypography,
  FdIconsV5,
  FdLoadingSpinner,
  FdSelect,
  FdMarkdownRender,
  FdTab,
  FdTooltip,
  FdButton,
  FdCard,
  useQueryRecursive,
  useSnapshot,
  globalStore,
  FdSkeleton,
  FdChip,
  FdExternalLink,
} from '@fifthdomain/fe-shared';
import ShowMoreText from 'react-show-more-text';
import TasksTable from '../components/Participant/TasksTable';
import AssessmentSummary from '../components/Participant/AssessmentSummary';
import {
  getTasksAttempts,
  listTaskActivities,
  listTaskActivitiesByAssessmentId,
  getTasksAttemptsByAssessmentId,
  getUserAssessment,
  listHintReveals,
} from '../queries/customQueries';
import {
  onStopGroupAssessment,
  onCreateNewTaskAttempt,
  onCreateTaskActivity,
  onUpdateTaskActivity,
} from '../graphql/subscriptions';
import FileAttachment from '../components/Participant/FileAttachment';
import FlagSubmission from '../components/Participant/FlagSubmission';
import {
  byModulePartId,
  listGroupMembersByGroupId,
  listTaskNotes,
  listTaskOpenedsByUserAssessmentId,
  getModulePart,
  downloadVPNConfigureFile,
} from '../graphql/queries';
import {
  createTaskOpened,
  attempt,
  stopGroupAssessment,
  createTaskActivity,
  updateTaskActivity,
  createTaskNote,
  updateTaskNote,
  deleteFinishedLabs,
  updateTaskOpened,
  startLab,
  stopLab,
} from '../graphql/mutations';
import useStopAssessment from '../hooks/useStopAssessment';
import {
  getAssessmentFinishDateTime,
  sortByDateField,
} from '../shared/utils/dateUtils';
import createEventsData from '../shared/utils/userEvents';
import setAppMarginRightByIds from '../shared/utils/layout';
import { TASK_ACTIVITY } from '../constants';
import { successToastMessage } from '../shared/utils/toast';
import NotesSubmission from '../components/Participant/NotesSubmission';
import LabControl from '../components/Participant/LabControl';
import TaskDrawerHeaderParticipant from '../components/Assessment/TaskDrawerHeaderParticipant';
import ChallengeRating from '../components/Participant/ChallengeRating';

const drawerWidth = 400;
const useStyles = makeStyles(() => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    '& .see-more': {
      color: '#1976D2',
      display: 'block',
      paddingTop: '0.5rem',
      textDecoration: 'none',
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  description: {
    wordWrap: 'break-word',
  },
}));
const toastSettings = {
  position: 'bottom-left',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  style: {
    border: '1px solid #FFEBEE',
  },
};

const AssessmentTasks = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [taskActivity, setTaskActivity] = useState('');
  const [assessmentName, setAssessmentName] = useState();
  const [taskAttempts, setTaskAttempts] = useState(undefined);
  const [taskStatus, setTaskStatus] = useState(undefined);
  const [activeTask, setActiveTask] = useState();
  const [activeTaskOpenedId, setActiveTaskOpenedId] = useState('');
  const [lastActiveTaskOpened, setLastActiveTaskOpened] = useState(undefined);
  const [tabIndex, setTabIndex] = useState(0);
  const [isJumboxStarting, setIsJumboxStarting] = useState(false);
  const [isLabReady, setIsLabReady] = useState(false);
  const { userId, user } = useSnapshot(globalStore);
  const { assessmentId } = useParams();
  const [restartingJumpBox, setRestartingJumpBox] = useState(false);
  const [isVPNdownload, setIsVPNdownload] = useState(false);
  const [isVPNCopying, setIsVPNCopying] = useState(false);
  const classes = useStyles();
  const { Close, InfoOutlined, EmojiObjects, Download, ContentCopy } =
    FdIconsV5;
  const jumpboxSharedModulepartId = '4ad5a685-8a58-460b-ac9b-45b522f37818';

  const { data: assessmentData, loading: assessmentLoading } = useQuery(
    gql(getUserAssessment),
    {
      variables: {
        id: assessmentId,
      },
      onCompleted: (_data) => {
        setAssessmentName(assessmentData?.getUserAssessment?.assessment?.name);
        createEventsData(
          userId,
          assessmentData?.getUserAssessment?.assessment?.name,
          'VIEW',
          {
            dimension1Id: assessmentId,
            dimension1Name: 'ASSESSMENT',
          },
        );
      },
      skip: !assessmentId,
      fetchPolicy: 'cache-and-network',
    },
  );

  // get the assessment selected
  const assessmentDataFiltered = assessmentData?.getUserAssessment;

  const {
    assessment: {
      hours,
      minutes,
      tasks,
      showPostAssessmentReflection,
      enableJumpbox,
      enableVPN,
    },
    id: userAssessmentId,
    startedOn,
  } = assessmentDataFiltered || { assessment: {}, id: {} };

  const teamBased = assessmentDataFiltered?.assessment?.teamBased;

  const [deleteFinishedLabsMutation] = useMutation(gql(deleteFinishedLabs));

  const onClickAttachment = (id, name) => {
    createEventsData(
      userId,
      name,
      'CLICK',
      {
        dimension1Id: assessmentId,
        dimension1Name: 'ASSESSMENT',
      },
      {
        dimension2Id: activeTask?.id,
        dimension2Name: 'TASK',
      },
      {
        dimension3Id: id,
        dimension3Name: 'ARTEFACT',
      },
    );
  };

  // get all task attempts for the assessment
  const {
    data: tasksAttemptsUserData,
    loading: tasksAttemptsLoading,
    refetch: refetchTasksAttempts,
  } = useQueryRecursive(gql(getTasksAttempts), {
    variables: {
      taskAttemptUserAssessmentId: assessmentDataFiltered?.id,
      limit: 10000,
    },
    onCompleted: (data) => {
      if (activeTask?.id) {
        const tasksData =
          data?.listTaskAttempts?.items?.filter(
            (t) => t.task?.id === activeTask?.id,
          ) || [];
        const attempts = tasksData?.length;
        const completed = tasksData?.filter((ta) => ta.success)?.length;
        let status = attempts > 0 ? 'Attempted' : taskStatus;
        if (completed) {
          status = 'Solved';
        }
        setTaskAttempts(attempts);
        setTaskStatus(status);
      }
    },
    skip: !assessmentDataFiltered?.id,
  });

  // get activity for the user
  const { data: tasksActivitiesData } = useQueryRecursive(
    gql(listTaskActivities),
    {
      variables: {
        filter: {
          userId: { eq: userId },
          assessmentId: { eq: assessmentDataFiltered?.assessment?.id },
        },
        limit: 1000,
      },
      skip: !assessmentDataFiltered?.id,
    },
  );

  // get activity for all users
  const {
    data: tasksActivitiesAllUsersData,
    refetch: refetchActivityAllUsers,
  } = useQueryRecursive(gql(listTaskActivitiesByAssessmentId), {
    variables: {
      assessmentId: assessmentDataFiltered?.assessment?.id,
      limit: 100000,
    },
    skip: !assessmentDataFiltered?.assessment?.id,
  });

  // get all task attempts for the assessment
  const {
    data: tasksAttemptsAssessmentData,
    refetch: refetchActivityTeamBased,
  } = useQueryRecursive(gql(getTasksAttemptsByAssessmentId), {
    variables: {
      assessmentId: assessmentDataFiltered?.assessment?.id,
      limit: 100000,
    },
    skip: !assessmentDataFiltered?.assessment?.id,
  });

  // get all task notes for the assessment
  const {
    data: tasksNotesData,
    loading: taskNotesLoading,
    refetch: refetchTaskNotes,
  } = useQueryRecursive(gql(listTaskNotes), {
    variables: {
      filter: {
        taskId: { eq: activeTask?.id },
        ...(teamBased
          ? { assessmentID: { eq: assessmentDataFiltered?.assessment?.id } }
          : { userAssessmentId: { eq: assessmentId } }),
        ...(teamBased
          ? { groupId: { eq: assessmentDataFiltered?.groupId } }
          : undefined),
      },
      limit: 100000,
    },
    skip: !activeTask?.id,
    onCompleted: (_data) => {
      if (activeTask?.id) {
        const taskNotes =
          _data?.listTaskNotes?.items
            .filter((t) => t.taskId === activeTask?.id)
            .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))[0]
            ?.notes || '';
        // eslint-disable-next-line no-use-before-define
        reset({ notes: taskNotes, flag: '' });
      }
    },
  });

  const { data: listHintRevealsData, loading: listHintRevealsLoading } =
    useQueryRecursive(gql(listHintReveals), {
      variables: {
        filter: {
          taskAssessmentId: { eq: activeTask?.taskAssessmentId },
        },
        limit: 1000,
      },
      skip: !activeTask?.taskAssessmentId || !activeTask?.hints?.items?.length,
    });

  const { data: groupMembers, loading: groupMembersLoading } = useQuery(
    gql(listGroupMembersByGroupId),
    {
      variables: {
        groupId: assessmentDataFiltered?.groupId,
        limit: 1000,
      },
      skip: !assessmentDataFiltered && teamBased,
    },
  );

  const { data: sharedModulePartData } = useQuery(gql(getModulePart), {
    variables: {
      id: jumpboxSharedModulepartId,
    },
  });

  const {
    data: labInstanceData,
    loading: labInstanceLoading,
    startPolling,
    stopPolling,
    refetch: refetchListLabInstances,
  } = useQueryRecursive(gql(byModulePartId), {
    variables: {
      userId: { eq: userId },
      modulePartId: jumpboxSharedModulepartId,
      filter: {
        or: [{ status: { eq: 'READY' } }, { status: { eq: 'POWERING_ON' } }],
        assessmentId: { eq: assessmentId },
      },
    },
    skip: enableJumpbox !== 'TRUE',
    onCompleted: (data) => {
      // start polling lab instance until its READY
      const labInstanceStatus = data?.byModulePartId?.items?.[0]?.status;
      if (labInstanceStatus && labInstanceStatus === 'READY') {
        setIsLabReady(true);
        setIsJumboxStarting(false);
        stopPolling();
        return;
      }
      // start while restart
      if (restartingJumpBox) {
        // eslint-disable-next-line no-use-before-define
        startLabMutation({
          variables: {
            labPrototypeId: sharedModulePartData?.getModulePart?.labId,
            modulePartId: sharedModulePartData?.getModulePart?.id,
            assessmentId,
          },
        });
      }
      setIsLabReady(false);

      if (data?.byModulePartId?.items.length > 0) {
        startPolling(5000);
      }
    },
  });

  const [startLabMutation, { loading: startLabLoading }] = useMutation(
    gql(startLab),
    {
      onError: () => {
        // retry start on error
        refetchListLabInstances();
        setRestartingJumpBox(true);
      },
      onCompleted: () => {
        refetchListLabInstances();
        setRestartingJumpBox(false);
      },
    },
  );

  const [stopLabMutation, { loading: stopLabLoading }] = useMutation(
    gql(stopLab),
    {
      onCompleted: () => {
        setTimeout(() => {
          refetchListLabInstances();
        }, 5000);
      },
    },
  );
  const [downloadVPNConfig] = useLazyQuery(gql(downloadVPNConfigureFile));

  const [createTaskOpenMutation] = useMutation(gql(createTaskOpened), {
    onCompleted: (data) => {
      setActiveTaskOpenedId(data.createTaskOpened?.id);
    },
  });
  const [updateTaskOpenedMutation, { loading: updateTaskOpenedLoading }] =
    useMutation(gql(updateTaskOpened), {
      onCompleted: (data) => {
        setActiveTaskOpenedId(data.updateTaskOpened?.id);
      },
    });

  const {
    data: taskOpenedData,
    loading: taskOpenedLoading,
    refetch: refetchTaskOpened,
  } = useQueryRecursive(gql(listTaskOpenedsByUserAssessmentId), {
    variables: {
      taskOpenedUserAssessmentId: assessmentId,
      limit: 1000,
    },
    skip: !assessmentId,
    staleTime: { seconds: 0 },
    onCompleted: (_data) => {
      const recentTaskOpenedData = [
        ...(_data?.listTaskOpenedsByUserAssessmentId?.items?.filter(
          (ta) => ta?.taskOpenedTaskId === activeTask?.id,
        ) || []),
      ]
        ?.filter((ot) => ot.id !== activeTaskOpenedId)
        ?.sort(sortByDateField('updatedAt', 'desc'));

      const recentTaskOpened = recentTaskOpenedData?.[0];
      setLastActiveTaskOpened(recentTaskOpened);

      const taskOpenedParams = {
        taskOpenedTaskId: activeTask?.id,
        taskOpenedUserAssessmentId: assessmentId,
        userId,
        assessmentId: assessmentDataFiltered?.assessment?.id,
        startedSolving: recentTaskOpened
          ? recentTaskOpened?.startedSolving
          : false,
        startedSolvingAt: recentTaskOpened?.startedSolvingAt
          ? recentTaskOpened?.startedSolvingAt
          : undefined,
      };
      if (activeTask?.id && activeTask?.status !== 'Solved') {
        createTaskOpenMutation({
          variables: {
            input: taskOpenedParams,
          },
        });
      }
    },
  });

  // there is a delay in activeTask being set and notes fetched being made
  // to avoid that this useEffect is used
  useEffect(() => {
    if (activeTask?.id) {
      refetchTaskNotes();
    }
  }, [activeTask, refetchTaskNotes]);

  // eslint-disable-next-line func-names
  Yup.addMethod(Yup.string, 'noWhitespace', function (message) {
    // eslint-disable-next-line react/no-this-in-sfc
    return this.test({
      name: 'noWhitespace',
      message,
      test: (value) => value && value.trim().length,
    });
  });

  const validationSchema = Yup.object().shape({
    flag: Yup.string().required('Please enter the flag to submit'),
    notes: Yup.string()
      .max(1000, 'Notes must be 1,000 characters or less')
      .noWhitespace('Please add notes before saving'),
  });
  const { control, reset, getValues, trigger } = useForm({
    defaultValues: { flag: '', notes: '' },
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const [createTaskNoteMutation] = useMutation(gql(createTaskNote));

  const [updateTaskNoteMutation] = useMutation(gql(updateTaskNote));

  const [
    stopGroupAssessmentMutation,
    { loading: stopGroupAssessmentProgress },
  ] = useMutation(gql(stopGroupAssessment), {
    refetchQueries: ['ListUserAssessmentsWithTasks'],
    awaitRefetchQueries: true,
    onCompleted: (_data) =>
      // eslint-disable-next-line no-use-before-define
      singleSpa.navigateToUrl(`/assessor/${assessmentRoute}/${assessmentId}`),
    onError: () => singleSpa.navigateToUrl('/assessor/error'),
  });

  const refreshActivity = (_data, action) => {
    const { assessmentId: activityAssessmentId, groupId: activityGroupId } =
      _data?.subscriptionData?.data?.[`on${action}TaskActivity`] || {
        activityAssessmentId: undefined,
        activityGroupId: undefined,
      };
    if (
      activityAssessmentId ===
        assessmentDataFiltered?.userAssessmentAssessmentId &&
      activityGroupId === assessmentDataFiltered?.groupId
    ) {
      refetchActivityAllUsers();
      refetchActivityTeamBased();
    }
  };
  useSubscription(gql(onCreateTaskActivity), {
    onSubscriptionData: (_data) => {
      refreshActivity(_data, 'Create');
    },
    fetchPolicy: 'cache-and-network',
  });

  useSubscription(gql(onUpdateTaskActivity), {
    onSubscriptionData: (_data) => {
      refreshActivity(_data, 'Update');
    },
    fetchPolicy: 'cache-and-network',
  });

  useSubscription(gql(onCreateNewTaskAttempt), {
    variables: {
      groupId: assessmentDataFiltered?.groupId,
      assessmentId: assessmentDataFiltered?.assessment?.id,
    },
    onSubscriptionData: (_data) => {
      const { success, user: subUser } = _data?.subscriptionData?.data
        ?.onCreateNewTaskAttempt || { success: false, user: undefined };
      refetchActivityAllUsers();
      refetchActivityTeamBased();
      if (success && userId !== subUser.id && teamBased) {
        successToastMessage(`${subUser.name} solved a challenge!`);
      }
    },
    fetchPolicy: 'cache-and-network',
    skip: !assessmentDataFiltered && teamBased,
  });

  useSubscription(gql(onStopGroupAssessment), {
    variables: {
      groupId: assessmentDataFiltered?.groupId,
      assessmentId: assessmentDataFiltered?.userAssessmentAssessmentId,
    },
    onSubscriptionData: async () => {
      // eslint-disable-next-line no-use-before-define
      await onNotesSubmission();
      successToastMessage('Assessment has ended');
      // eslint-disable-next-line no-use-before-define
      singleSpa.navigateToUrl(`/assessor/${assessmentRoute}/${assessmentId}`);
    },
    fetchPolicy: 'cache-and-network',
    skip: !assessmentDataFiltered && teamBased,
  });

  const assessmentRoute = showPostAssessmentReflection
    ? 'assessment-post-reflection'
    : 'assessment-complete';

  const [stopAssessment, { loading: stopAssessmentInProgress }] =
    useStopAssessment(showPostAssessmentReflection);

  const getCurrentActivity = (_taskId) =>
    tasksActivitiesData?.listTaskActivities?.items.find(
      (ta) =>
        ta.taskId === _taskId &&
        ta.userId === userId &&
        ta.assessmentId === assessmentDataFiltered?.userAssessmentAssessmentId,
    );

  const getCurrentActivityLabel = (_taskId) => {
    const activity = getCurrentActivity(_taskId)?.activity;
    return TASK_ACTIVITY.find((t) => t.value === activity)?.desc || '';
  };

  const teamMembers =
    groupMembers?.listGroupMembersByGroupId?.map((member) => member.id) || [];

  const allUserActivity =
    tasksActivitiesAllUsersData?.listTaskActivitiesByAssessmentId?.items
      .filter((la) => teamMembers?.includes(la.userId))
      .map((a) => ({
        activity: a.activity,
        name: groupMembers?.listGroupMembersByGroupId?.find(
          (gm) => gm.id === a.userId,
        )?.name,
        taskId: a.taskId,
      })) || [];

  const tasksAttemptsData =
    (assessmentDataFiltered?.assessment?.teamBased
      ? tasksAttemptsAssessmentData?.listTaskAttempts?.items?.filter((ta) =>
          teamMembers?.includes(ta.userId),
        )
      : tasksAttemptsUserData?.listTaskAttempts?.items) || [];

  const tableRows =
    tasks?.items?.map((i) => {
      const allTaskAttempts =
        tasksAttemptsData?.filter((ta) => ta.task.id === i.task.id) || [];
      const attempts = allTaskAttempts?.length;
      const completed = allTaskAttempts?.filter((ta) => ta.success)?.length;
      const taskOpened =
        [
          ...(taskOpenedData?.listTaskOpenedsByUserAssessmentId?.items?.sort(
            sortByDateField('updatedAt', 'desc'),
          ) || []),
        ]?.filter((ta) => ta.taskOpenedTaskId === i.task.id) || [];
      const taskStarted = taskOpened?.[0]?.startedSolving;
      let status =
        attempts > 0
          ? 'Attempted'
          : taskStarted
          ? 'Started'
          : taskOpened?.length > 0
          ? 'Opened'
          : 'Incomplete';
      if (completed) {
        status = 'Solved';
      }
      return {
        ...i.task,
        status,
        attempts,
        activities: allUserActivity.filter((ua) => ua.taskId === i.task.id),
        specialty: i?.task?.specialty?.name,
        skills: i?.task?.skills?.items?.map((s) => s?.skill?.name) || [],
        creator: i.task?.org?.name,
        modulePartId: i.modulePartId,
        modulePart: i.modulePart,
        labId: i.task?.labId,
        taskAssessmentId: i?.id,
      };
    }) || [];

  const tasksCompleted = tableRows?.reduce(
    (p, c) => p + (c.status === 'Solved' ? 1 : 0),
    0,
  );

  const handleOpenDrawer = (drawerState) => {
    const mainPageIds = ['topnav'];
    setOpenDrawer(drawerState);
    const marginRightBy = drawerState ? '400px' : '0';
    setAppMarginRightByIds(mainPageIds, marginRightBy);
  };

  const [createTaskActivityMutation] = useMutation(gql(createTaskActivity), {
    refetchQueries: ['ListTaskActivities', 'ListTasksAttempts'],
    awaitRefetchQueries: true,
  });

  const [updateTaskActivityMutation] = useMutation(gql(updateTaskActivity), {
    refetchQueries: ['ListTaskActivities', 'ListTasksAttempts'],
    awaitRefetchQueries: true,
  });

  const onActivityChange = (value) => {
    setTaskActivity(value);
    const currentActivityId = getCurrentActivity(activeTask?.id)?.id;
    const activity = TASK_ACTIVITY.find((t) => t.desc === value)?.value;
    // if no activity present create else update
    if (currentActivityId) {
      updateTaskActivityMutation({
        variables: {
          input: {
            id: currentActivityId,
            activity,
          },
        },
      });
    } else {
      createTaskActivityMutation({
        variables: {
          input: {
            userId,
            assessmentId: assessmentDataFiltered?.assessment?.id,
            taskId: activeTask?.id,
            groupId: assessmentDataFiltered?.groupId,
            activity,
          },
        },
      });
    }
  };

  const [submitAttempt, { loading: flagSubmissionLoading }] = useMutation(
    gql(attempt),
    {
      onCompleted: (_data) => {
        refetchTasksAttempts();
        if (_data.attempt.success) {
          toast.success(
            'Success! You have entered the correct flag.',
            toastSettings,
          );

          createEventsData(
            userId,
            activeTask?.name,
            'SUBMIT_SUCCESS',
            {
              dimension1Id: assessmentId,
              dimension1Name: 'ASSESSMENT',
            },
            {
              dimension2Id: activeTask?.id,
              dimension2Name: 'TASK',
            },
          );
        } else {
          toast.error('You did not get the right flag.', toastSettings);

          createEventsData(
            userId,
            activeTask?.name,
            'SUBMIT_FAILED',
            {
              dimension1Id: assessmentId,
              dimension1Name: 'ASSESSMENT',
            },
            {
              dimension2Id: activeTask?.id,
              dimension2Name: 'TASK',
            },
          );
        }
      },
    },
  );

  if (
    assessmentLoading ||
    stopAssessmentInProgress ||
    stopGroupAssessmentProgress ||
    groupMembersLoading
  ) {
    return <FdLoadingSpinner />;
  }

  const hasActiveTaskStartedSolving = lastActiveTaskOpened?.startedSolving;

  const onSubmitFlag = (flag) => {
    submitAttempt({
      variables: {
        answer: flag,
        taskId: activeTask?.id,
        userAssessmentId,
      },
    });

    createEventsData(
      userId,
      activeTask?.name,
      'SUBMIT',
      {
        dimension1Id: assessmentId,
        dimension1Name: 'ASSESSMENT',
      },
      {
        dimension2Id: activeTask?.id,
        dimension2Name: 'TASK',
      },
    );

    reset();
  };

  const ActivitySelector = ({ activity }) => (
    <Box mt={1} mb={2}>
      <FdSelect
        id="taskActivity"
        defaultSelected={activity}
        options={TASK_ACTIVITY.map((t) => t.desc)}
        onChange={onActivityChange}
        placeholder="Select Activity"
        data-cy="task-activity"
      />
    </Box>
  );

  ActivitySelector.propTypes = {
    activity: PropTypes.string.isRequired,
  };

  const onNotesSubmission = async () => {
    const taskNoteId = tasksNotesData?.listTaskNotes?.items.sort(
      (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt),
    )[0]?.id;
    const resetNotes = () => {
      refetchTaskNotes();
      reset({ flag: '', notes: getValues('notes') });
      successToastMessage('Success! Notes saved');
    };
    const notes = getValues('notes');
    // edit if present
    if (taskNoteId) {
      updateTaskNoteMutation({
        variables: {
          input: {
            id: taskNoteId,
            notes,
          },
        },
        onCompleted: () => resetNotes(),
      });
    } else if (activeTask?.id) {
      // create if not present
      createTaskNoteMutation({
        variables: {
          input: {
            taskId: activeTask?.id,
            userId,
            userAssessmentId: assessmentId,
            assessmentID: assessmentDataFiltered?.assessment?.id,
            notes,
            ...(teamBased
              ? { groupId: assessmentDataFiltered?.groupId }
              : undefined),
          },
        },
        onCompleted: () => resetNotes(),
      });
    }

    createEventsData(
      userId,
      activeTask?.name,
      'ADDNOTE',
      {
        dimension1Id: assessmentId,
        dimension1Name: 'ASSESSMENT',
      },
      {
        dimension2Id: activeTask?.id,
        dimension2Name: 'TASK',
      },
    );
  };

  if (assessmentDataFiltered?.status === 'FINISHED') {
    singleSpa.navigateToUrl(`/assessor/${assessmentRoute}/${assessmentId}`);
  }

  if (assessmentDataFiltered?.status !== 'STARTED') {
    singleSpa.navigateToUrl('/landing/landing-homepage');
  }

  const labInstance = labInstanceData?.byModulePartId?.items?.[0];

  return (
    <BasePage
      heading={assessmentName}
      data-cy="participant-tasks"
      breadCrumbs={[{ url: '/landing/landing-homepage', name: 'Home' }]}
      currentPageBreadcrumbLabel={assessmentName}
      linkComponent={RouterLink}
      renderBreadCrumbAsButton
    >
      <Grid container>
        <Grid item xs>
          <ToastContainer />
          <Box mb={2}>
            <AssessmentSummary
              assessment={{
                hours,
                minutes,
                endDateTime: getAssessmentFinishDateTime(
                  startedOn,
                  hours,
                  minutes,
                ),
                tasksCompleted,
                teamBased,
              }}
              onFinish={async () => {
                await onNotesSubmission();
                if (teamBased) {
                  // stop team assessment
                  stopGroupAssessmentMutation({
                    variables: {
                      groupId: assessmentDataFiltered?.groupId,
                      assessmentId:
                        assessmentDataFiltered?.userAssessmentAssessmentId,
                    },
                  });
                } else {
                  // stop individual assessment
                  stopAssessment({
                    variables: {
                      userAssessmentId,
                    },
                  });
                }

                // delete VMs after assessment is finished
                deleteFinishedLabsMutation({
                  variables: {
                    userAssessmentId: assessmentId,
                  },
                });

                createEventsData(
                  userId,
                  activeTask?.name,
                  'FINISH',
                  {
                    dimension1Id: assessmentId,
                    dimension1Name: 'ASSESSMENT',
                  },
                  {
                    dimension2Id: activeTask?.id,
                    dimension2Name: 'TASK',
                  },
                );
              }}
            />
          </Box>
          {(enableJumpbox === 'TRUE' || enableVPN === 'TRUE') && (
            <Box display="flex" justifyContent="space-between">
              {enableVPN === 'TRUE' && (
                <FdCard
                  variant="outlined"
                  style={{
                    width:
                      enableJumpbox === 'TRUE' && enableVPN === 'TRUE'
                        ? '49%'
                        : '100%',
                  }}
                >
                  <Box>
                    <Box mb={2}>
                      <FdTypography variant="subtitle1">
                        VPN Configuration
                      </FdTypography>
                    </Box>
                    <Box mb={2} className="flex items-center">
                      <Box>
                        <FdButton
                          startIcon={<Download />}
                          disabled={!!isVPNdownload}
                          onClick={async () => {
                            setIsVPNdownload(true);
                            const orgId = assessmentDataFiltered?.user?.orgId;
                            downloadVPNConfig({
                              variables: {
                                userAssessmentId: assessmentDataFiltered?.id,
                                assessmentId:
                                  assessmentDataFiltered?.assessment?.id,
                                orgId,
                              },
                              onCompleted: (data) => {
                                const text = atob(
                                  data.downloadVPNConfigureFile,
                                );
                                const blob = new Blob([text], {
                                  type: 'text/plain',
                                });
                                const url = URL.createObjectURL(blob);
                                const a = document.createElement('a');
                                a.href = url;
                                a.download = `user-config-${assessmentDataFiltered?.assessment?.id}.ovpn`;
                                a.click();
                                URL.revokeObjectURL(url);
                                setIsVPNdownload(false);
                              },
                            });
                          }}
                        >
                          {isVPNdownload
                            ? 'Waiting...'
                            : 'Download VPN Configuration File'}
                        </FdButton>
                      </Box>
                      <Box ml={1}>
                        <FdTypography variant="body1">or</FdTypography>
                      </Box>
                      <Box ml={1}>
                        <FdButton
                          startIcon={<ContentCopy />}
                          disabled={!!isVPNCopying}
                          onClick={async () => {
                            setIsVPNCopying(true);
                            const orgId = assessmentDataFiltered?.user?.orgId;
                            const key = `user-config-${assessmentDataFiltered?.assessment?.id}-fd.ovpn`;
                            const currentDate = new Date();
                            const expiryDate = new Date(currentDate);
                            expiryDate.setHours(currentDate.getHours() + 24);

                            try {
                              await Storage.get(key, {
                                level: 'private',
                                contentType: 'text/plain',
                                cacheControl: 'no-cache',
                                validateObjectExistence: true,
                              });

                              const userConfigLink = await Storage.get(key, {
                                level: 'private',
                                contentType: 'text/plain',
                                cacheControl: 'no-cache',
                              });

                              navigator.clipboard
                                .writeText(userConfigLink)
                                .then(() => {
                                  successToastMessage(
                                    'VPN Configuration link copied successfully.',
                                  );
                                });

                              setIsVPNCopying(false);
                            } catch (error) {
                              downloadVPNConfig({
                                variables: {
                                  userAssessmentId: assessmentDataFiltered?.id,
                                  assessmentId:
                                    assessmentDataFiltered?.assessment?.id,
                                  orgId,
                                },
                                onCompleted: async (data) => {
                                  const text = atob(
                                    data.downloadVPNConfigureFile,
                                  );

                                  const expiryDate48 = new Date(currentDate);
                                  expiryDate48.setHours(
                                    currentDate.getHours() + 48,
                                  );

                                  await Storage.put(key, text, {
                                    level: 'private',
                                    contentType: 'text/plain',
                                    cacheControl: 'no-cache',
                                    expires: expiryDate48,
                                  });

                                  const userConfigLink = await Storage.get(
                                    key,
                                    {
                                      level: 'private',
                                      cacheControl: 'no-cache',
                                    },
                                  );

                                  navigator.clipboard
                                    .writeText(userConfigLink)
                                    .then(() => {
                                      successToastMessage(
                                        'VPN Configuration link copied successfully.',
                                      );
                                    });

                                  setIsVPNCopying(false);
                                },
                              });
                            }
                          }}
                        >
                          {isVPNCopying
                            ? 'Waiting...'
                            : 'Copy VPN Configuration Link'}
                        </FdButton>
                      </Box>
                    </Box>
                    <Box mb={2}>
                      <FdTypography variant="body2">
                        You must use the provided VPN file on your workstation
                        or jump box to securely connect to the lab virtual
                        machines(VMs) in each challenge. You can either download
                        the VPN configuration file directly or copy the download
                        URL to into the jumpbox or elsewhere.
                        <FdExternalLink
                          href="https://au.intercom.help/fifth-domain/en/articles/2396-using-vpn-in-a-competition"
                          noUnderline
                        >
                          Click here
                        </FdExternalLink>
                        to learn more about using VPN configuration file.
                      </FdTypography>
                    </Box>
                  </Box>
                </FdCard>
              )}
              {enableJumpbox === 'TRUE' && (
                <FdCard
                  variant="outlined"
                  style={{
                    width:
                      enableJumpbox === 'TRUE' && enableVPN === 'TRUE'
                        ? '50%'
                        : '100%',
                  }}
                >
                  <Box mb={2}>
                    <FdTypography variant="subtitle1">Jump Box</FdTypography>
                  </Box>
                  <Box className="flex items-center gap-x-3 my-3">
                    <FdSkeleton
                      loading={
                        labInstanceLoading ||
                        isJumboxStarting ||
                        startLabLoading ||
                        stopLabLoading
                      }
                      height="32px"
                      width="198px"
                    >
                      <FdButton
                        style={
                          isLabReady
                            ? {
                                backgroundColor: '#228b22',
                                color: '#FFFFFF',
                                borderColor: '#228b22',
                              }
                            : {}
                        }
                        onClick={() => {
                          if (isLabReady) {
                            const vmId =
                              labInstanceData?.byModulePartId?.items?.[0]?.vms
                                ?.items?.[0]?.id;
                            window.open(
                              `/assessor/connect/${labInstance?.id}/vdi/${vmId}`,
                            );
                            refetchListLabInstances();
                          } else if (!startLabLoading && !labInstanceLoading) {
                            setIsJumboxStarting(true);
                            startLabMutation({
                              variables: {
                                labPrototypeId:
                                  sharedModulePartData?.getModulePart?.labId,
                                modulePartId:
                                  sharedModulePartData?.getModulePart?.id,
                                assessmentId,
                              },
                            });
                          }
                        }}
                      >
                        {!isLabReady ||
                        startLabLoading ||
                        labInstanceLoading ? (
                          <Box display="flex" alignItems="center">
                            <Box mr={2}>Start Jump Box</Box>
                            {isJumboxStarting && (
                              <CircularProgress
                                size={25}
                                style={{ color: '#fff' }}
                              />
                            )}
                          </Box>
                        ) : (
                          'Connect to Jump Box'
                        )}
                      </FdButton>
                    </FdSkeleton>
                    {['POWERING_ON', 'POWERING_OFF']?.includes(
                      labInstance?.status,
                    ) && (
                      <FdChip
                        color="warning"
                        size="medium"
                        label={
                          labInstance?.status === 'POWERING_ON'
                            ? 'Starting'
                            : 'Stopping'
                        }
                        className="ml-2"
                      />
                    )}
                    {isLabReady && (
                      <FdButton
                        variant="secondary"
                        disabled={isJumboxStarting || restartingJumpBox}
                        onClick={() => {
                          setIsJumboxStarting(true);
                          setRestartingJumpBox(true);
                          stopLabMutation({
                            variables: {
                              labInstanceId: labInstance?.id,
                            },
                          });
                        }}
                      >
                        Restart Jump box
                      </FdButton>
                    )}
                  </Box>
                  <FdTypography variant="body2">
                    You can use the Jump Box to securely access the challenges.
                    Check the Assessment Overview, accessible via left
                    navigation to see the credentials for the Jump Box.
                  </FdTypography>
                </FdCard>
              )}
            </Box>
          )}
          {tableRows.length > 0 && (
            <TasksTable
              rows={tableRows}
              teamBased={teamBased}
              onRowClick={async (value) => {
                setActiveTaskOpenedId('');
                setLastActiveTaskOpened(undefined);
                refetchTaskOpened();

                handleOpenDrawer(true);
                const selectedTask = tableRows?.find(
                  (t) => t.id === value?.row?.id,
                );
                setActiveTask(selectedTask);
                setTaskStatus(selectedTask.status);
                setTaskAttempts(selectedTask.attempts);

                createEventsData(
                  userId,
                  selectedTask?.name,
                  'CLICK',
                  {
                    dimension1Id: assessmentId,
                    dimension1Name: 'ASSESSMENT',
                  },
                  {
                    dimension2Id: selectedTask?.id,
                    dimension2Name: 'TASK',
                  },
                );

                const activityLabel =
                  getCurrentActivityLabel(value?.row?.id) || '';
                setTaskActivity(activityLabel);
                setTabIndex(0);
              }}
              loading={tasksAttemptsLoading}
            />
          )}
        </Grid>
        <Grid
          item
          style={{
            width: openDrawer ? drawerWidth : 0,
          }}
        />
      </Grid>
      <Drawer
        variant="persistent"
        anchor="right"
        open={openDrawer}
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper,
        }}
        data-cy="drawer"
      >
        <Card
          style={{ overflow: 'auto', height: 'inherit' }}
          data-cy="drawer-card"
        >
          <CardHeader
            action={
              <IconButton
                onClick={() => {
                  setActiveTaskOpenedId('');
                  setLastActiveTaskOpened(undefined);

                  handleOpenDrawer(false);
                }}
              >
                <Close style={{ fontSize: 28 }} />
              </IconButton>
            }
            title={activeTask?.name}
          />
          <CardContent
            style={{ height: '795px', paddingTop: 0 }}
            data-cy="drawer-content"
          >
            <TaskDrawerHeaderParticipant
              activeTask={activeTask}
              taskStatus={taskStatus}
              taskAttempts={taskAttempts}
              loading={tasksAttemptsLoading}
            />
            {!hasActiveTaskStartedSolving && (
              <>
                <Box mt={2} mb={2} className={classes.description}>
                  <FdTypography variant="subtitle1" data-cy="description-title">
                    Description
                  </FdTypography>
                  <FdTypography
                    variant="body1"
                    data-cy="description"
                    color="secondary"
                  >
                    <ShowMoreText
                      lines={8}
                      more="See more"
                      less="See less"
                      anchorClass="see-more"
                    >
                      <FdMarkdownRender markdown={activeTask?.description} />
                    </ShowMoreText>
                  </FdTypography>
                </Box>
                {activeTask?.skills?.length > 0 && (
                  <Box mb={2}>
                    <FdTypography variant="subtitle1" data-cy="skills-title">
                      Skills
                    </FdTypography>
                    <FdTypography variant="body1" color="secondary">
                      {activeTask?.skills?.map((skill) => (
                        <ul key={shortid.generate()} data-cy="skills">
                          <li>{skill}</li>
                        </ul>
                      ))}
                    </FdTypography>
                  </Box>
                )}
              </>
            )}

            {taskOpenedLoading || updateTaskOpenedLoading ? (
              <FdLoadingSpinner />
            ) : hasActiveTaskStartedSolving ? (
              <>
                <FdTab
                  variant="fullWidth"
                  custIndex={tabIndex}
                  label={[
                    {
                      label: 'Challenge Details',
                      path: '/assessor/',
                      index: 0,
                      data: () => (
                        <Box>
                          {teamBased && taskStatus !== 'Solved' && (
                            <ActivitySelector activity={taskActivity} />
                          )}
                          <Box mb={2} className={classes.description}>
                            <FdTypography
                              variant="subtitle1"
                              data-cy="description-title"
                            >
                              Description
                            </FdTypography>
                            <FdTypography
                              variant="body1"
                              data-cy="description"
                              color="secondary"
                            >
                              <ShowMoreText
                                lines={8}
                                more="See more"
                                less="See less"
                                anchorClass="see-more"
                              >
                                <FdMarkdownRender
                                  markdown={activeTask?.description}
                                />
                              </ShowMoreText>
                            </FdTypography>
                          </Box>
                          {activeTask?.skills?.length > 0 && (
                            <Box mb={2}>
                              <FdTypography
                                variant="subtitle1"
                                data-cy="skills-title"
                              >
                                Skills
                              </FdTypography>
                              <FdTypography variant="body1" color="secondary">
                                {activeTask?.skills?.map((skill) => (
                                  <ul key={shortid.generate()} data-cy="skills">
                                    <li>{skill}</li>
                                  </ul>
                                ))}
                              </FdTypography>
                            </Box>
                          )}
                          <LabControl
                            assessmentId={assessmentId}
                            activeTask={activeTask}
                            user={user}
                            createEventsData={createEventsData}
                            labId={activeTask?.labId}
                            modulePartId={activeTask?.modulePartId}
                          />
                          {taskStatus !== 'Solved' && (
                            <Box>
                              {activeTask?.files?.items?.length !== 0 && (
                                <Box mb={2}>
                                  <FdTypography
                                    variant="subtitle1"
                                    data-cy="attachments-title"
                                  >
                                    Attachments
                                  </FdTypography>
                                  {activeTask?.files?.items
                                    ?.filter((file) =>
                                      [
                                        'application/x-zip-compressed',
                                        'application/zip',
                                      ].includes(file.type),
                                    )
                                    .map((f) => (
                                      <FileAttachment
                                        id={f.id}
                                        key={f.id}
                                        name={f.name}
                                        url={f.url}
                                        onClickAttachment={(id, name) =>
                                          onClickAttachment(id, name)
                                        }
                                      />
                                    ))}
                                </Box>
                              )}
                              {listHintRevealsLoading ? (
                                <FdLoadingSpinner />
                              ) : (
                                listHintRevealsData?.listHintReveals?.items
                                  ?.length > 0 && (
                                  <Box mb={2}>
                                    <FdTypography variant="subtitle1">
                                      Hints
                                    </FdTypography>
                                    <FdTypography
                                      variant="body1"
                                      color="secondary"
                                    >
                                      {listHintRevealsData?.listHintReveals?.items?.map(
                                        (item) => (
                                          <Box key={item?.id}>
                                            <Box
                                              display="flex"
                                              flexDirection="row"
                                              mt={1.5}
                                            >
                                              <EmojiObjects />
                                              <Box ml={1.5}>
                                                {
                                                  activeTask?.hints?.items?.find(
                                                    (_item) =>
                                                      _item?.id ===
                                                      item?.hintId,
                                                  )?.text
                                                }
                                              </Box>
                                            </Box>
                                          </Box>
                                        ),
                                      )}
                                    </FdTypography>
                                  </Box>
                                )
                              )}

                              <FlagSubmission
                                onClickSubmit={async () => {
                                  if (await trigger('flag')) {
                                    onSubmitFlag(getValues('flag'));
                                  }
                                }}
                                loading={flagSubmissionLoading}
                                control={control}
                                Controller={Controller}
                              />
                            </Box>
                          )}
                        </Box>
                      ),
                    },
                    {
                      label: (
                        <Box display="flex" alignItems="center">
                          <Box>Notes</Box>
                          <FdTooltip
                            position="top"
                            title="Notes let you explain your approach to your assessment organiser.
This can help you share what steps you took, which parts of the challenge you solved, or what you would have tried if you had more time."
                          >
                            <Box pl={1}>
                              <InfoOutlined />
                            </Box>
                          </FdTooltip>
                        </Box>
                      ),
                      path: '/assessor/',
                      index: 1,
                      data: () =>
                        taskNotesLoading ? (
                          <FdLoadingSpinner />
                        ) : (
                          <NotesSubmission
                            onClickSubmit={onNotesSubmission}
                            loading={flagSubmissionLoading}
                            control={control}
                            Controller={Controller}
                            reset={reset}
                            getValues={getValues}
                            trigger={trigger}
                          />
                        ),
                    },
                  ]}
                />
                <ChallengeRating
                  userId={userId}
                  assessmentId={assessmentDataFiltered?.assessment?.id}
                  taskId={activeTask?.id}
                  userAssessmentId={assessmentId}
                />
              </>
            ) : (
              <>
                <FdTypography color="secondary">
                  Click below to see detailed challenge view including
                  attachments, virtual environments, and hints to start solving
                  the challenge.
                </FdTypography>
                <FdButton
                  variant="secondary"
                  onClick={() => {
                    updateTaskOpenedMutation({
                      variables: {
                        input: {
                          id: activeTaskOpenedId,
                          startedSolving: true,
                          startedSolvingAt: new Date().toISOString(),
                        },
                      },
                      onCompleted: (_data) => {
                        setLastActiveTaskOpened(_data.updateTaskOpened);
                        setActiveTaskOpenedId('');
                      },
                    });
                  }}
                  style={{ marginTop: '16px' }}
                >
                  Start Solving
                </FdButton>
              </>
            )}
          </CardContent>
        </Card>
      </Drawer>
    </BasePage>
  );
};

export default AssessmentTasks;
